import request from '@/utils/request';
/** 列表 */
export function listPageOrder(data) {
    return request({
        url: '/order/listPageOrder',//请求路径
        method: 'get',
        params: data
    });
}
/** 新增工单 */
export function addOrder(data) {
    return request({
        url: '/order/addOrder',//请求路径
        method: 'post',
        data
    });
}
/** 编辑工单 */
export function modifyOrder(data) {
    return request({
        url: '/order/modifyOrder',//请求路径
        method: 'post',
        data
    });
}
/** 删除工单 */
export function deleteOrder(data) {
    return request({
        url: '/order/deleteByKey',//请求路径
        method: 'post',
        data
    });
}
/* 获取用户信息 */
export function getCurrentUser(data) {
    return request({
        url: '/rbac/user/getCurrentUser',
        method: 'get',
        params: data
    })
}