<template>
    <div class="login-container"> 
        <div class="logo">
            <img src="@/assets/images/login/logo-signin.png"/>
            <span>Hello，欢迎来到农机智慧农业园区！</span>
        </div>
        <!-- 登录 -->
        <van-form @submit="onSubmit" class="login-form">
            <div v-if="!guestLogin">
                <van-field
                    class="user-name"
                    v-model="username"
                    name="username"
                    left-icon="smile-o"
                    label=""
                    placeholder='请填写用户名'
                    :rules="[{ required: true}]"
                />
                <van-field
                    class="user-password user-last"
                    v-model="password"
                    type="password"
                    name="password"
                    left-icon="music-o"
                    label=""
                    placeholder="请填写密码"
                    :rules="[{ required: true }]"
                />
            </div>
            <div v-else>
               <!--  <van-field
                    class="user-name"
                    v-model="username"
                    name="username"
                    left-icon="smile-o"
                    label=""
                    placeholder='请填写用户名'
                    :rules="[{ required: true}]"
                    required
                /> -->
                <van-field
                    v-model="smsObj.username"
                    name="username"
                    left-icon="smile-o"
                    class="user-password"
                    label=""
                    placeholder="请填写手机号码"
                    :rules="[{ required: true}]"
                    required
                />
                <div class="user-last code-container">
                    <van-field
                        v-model="smsObj.password"
                        name="password"
                        label=""
                        class="code-input"
                        placeholder="请输入验证码"
                        :rules="[{ required: true}]"
                        required
                    >
                    </van-field>
                    <van-button class="btn-code" size="small" type="primary" @click="getCode" :disabled="btnText != '发送验证码'">{{btnText}}</van-button>
                </div>
            </div>
            <div class="btn-login-container">
                <van-button class="btn-login" round block type="info" native-type="submit">登录</van-button>
            </div>
            <div class="guest-login" @click="changeLogin">{{checkText}}</div> 
        </van-form>
    </div>
</template>

<script>
import {login, logout,getCode, codeLogin} from '@/api/user';
import {
    getCurrentUser
} from '@/api/workOrder';
import {
    setUserId,
    getToken, 
    setToken,
    setOrgId,
    removeToken,
    setUserType,
    removeUserType,
    setUsername
} from '@/utils/cookie'
export default {
    data() {
        return {
            username: '',//用户名
            password: '',//密码
            showPopover: false,
            checkText: '游客登录',
            guestLogin: false,//判断是游客登录还是账号密码登录
            phone: '',//手机号
            code: '',
            btnText: '发送验证码',
            smsObj: {
                username: '',
                password: ''
            }
        }
    },
    methods: {
        /** 提交 */
        onSubmit(values) {
            // removeUserType();
            if (!this.guestLogin) {
                let loginObj = JSON.parse(JSON.stringify(values));
                loginObj.password = this.RSA.getRsaCode(loginObj.password);
                login(loginObj).then(res => {
                    if (res.code == 200) {
                        setToken(res.data.token);
                        setOrgId(res.data.orgId);
                        setUsername(res.data.userName);
                        this.getCurrentUser();
                    }else{
                        this.$notify({type: 'warning', message: res.message});
                    }
                })
            } else {
                let obj = {
                    username: this.smsObj.username,
                    password: this.smsObj.password
                }
                codeLogin(obj).then(res => {
                    if (res.code == 200) {
                        console.log(res.data)
                        setToken(res.data.token);
                        setOrgId(res.data.orgId);
                        setUsername(res.data.userName);
                        //数据库中没有找到匹配电话 完全的游客
                        if(res.data.type == null){
                            setUserType(0);
                        }else{
                            setUserType(res.data.type);
                        }
                        this.$router.push({path: '/console'})
                    }else{
                        this.$notify({type: 'warning', message: res.message});
                    }
                })
            }
            
        },
        /** 切换登录方式 */
        changeLogin() {
            this.guestLogin = !this.guestLogin;
            this.checkText = this.guestLogin ? '账号密码登录' : '游客登录';
        },
        /** 判断是否是管理员 */
        getCurrentUser() {
            getCurrentUser().then(res => {
                if (res.code == 200) {
                    setUserId(res.data.userId);
                    setUserType(res.data.type);
                    this.$router.push({path: '/console'})
                }
            })
        },
        /** 获取验证码 */
        getCode() {
            let num = 59;
            let obj = {
                value: this.smsObj.username
            }
            let timer = setInterval(() => {
                if (num >= 0) {
                    this.btnText = num;
                    num--;
                } else {
                    clearInterval(timer);
                    this.btnText = '发送验证码';
                }
                
            }, 1000);
            getCode(obj).then(res => {
                if (res.code == 200) {
                    this.btnText = '发送验证码';
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .login-container {
        .logo {
            display: flex;
            padding: 30px 27px;
            img {
                width: 56px;
                height: 56px;
                margin-right: 18px;
            }
            span {
                font-weight: 500;
                font-size: 20px;
            }
        }
        // padding-top: 200px;
        .login-form {
            width: 321px;
            margin: 0 auto;
            margin-top: 8px;
            .user-name {
                margin-bottom: 16px;
                .van-icon-smile-o::before {
                    content: url(../assets/images/login/icon_signin_name.png);
                }
            }
            .user-password {
                margin-bottom: 16px;
            }
            .user-last {
                margin-bottom: 127px;
            }
            .btn-login-container {
                width: 321px;
                margin: 0 auto;
                .btn-login {
                    border-radius: 4px;
                    background: #4499FF;
                }
            }
            .guest-login {
                margin: 10px 0;
                margin-top: 20px;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
                color: #4499FF;
            }
        }
        .code-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .code-input {
                width: 200px;
            }
            .btn-code {
                width: 80px;
                height: 50px;
                border-radius: 4px;
               /*  border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0; */
            }
        }
        
    }
</style>
<style lang="scss">
    .login-container {
        height: 100%;
        background-color: #fff;
        .van-cell {
            height: 50px;
            border: 1px solid#DBDBDB;
            border-radius: 4px;
        }
        .van-cell::after,.van-cell::before {
            border-bottom: 0;
        }
        .user-name {
            .van-icon-smile-o::before {
                content: '';
                width: 16px;
                height: 16px;
                background: url(../assets/images/login/icon_signin_name.png);
                background-size: contain;
            }
        }
        .user-password {
            .van-icon-music-o::before {
                content: '';
                width: 16px;
                height: 16px;
                background: url(../assets/images/login/icon_signin_password.png);
                background-size: contain;
            }  
            .van-icon-smile-o::before {
                content: '';
                width: 16px;
                height: 16px;
                background: url(../assets/images/login/icon_signin_phone.png);
                background-size: contain;
            }
        }
        .van-field__left-icon {
            font-size: 16px;
        }
    }
</style>